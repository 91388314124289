import React, {useState} from 'react';
import $ from 'jquery';
const contentful = require('contentful');
const ReactMarkdown = require('react-markdown')

class Categories extends React.Component{

    componentDidMount(){
        $(".homeicons").css( "max-width", $(".homeicons").width() * 1.1);
        $(".homeicon").height( $(".homeicon").width());
    }

    render(){
        return (
            <div class="container homeicons">
                <div class="row homeiconrow">
                    {
                        this.props.listOfTitles.map((category, i) => {
                            return <div class="col-md-3 homeicon" style={{"backgroundImage": "url("+this.props.listOfImages[i].fields.file.url+")"}}>
                                <button class="ctaButton" onClick={()=>window.location=this.props.listOfLinks[i]}>{category}</button>
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }

}

export default Categories;
