import React from 'react';
import $ from 'jquery';
import '../flowers.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const contentful = require('contentful');
const ReactMarkdown = require('react-markdown');

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

function updateQueryStringParameter(uri, key, value) {
    if(uri.includes(key)){
        var queryString = uri.split("?");
        var queryVars = queryString[1].split("&");
        for(let i=0;i<queryVars.length;i++){
            var qvar = queryVars[i].split("=");
            if(qvar[0] === key){
                qvar[1] = qvar[1] + value;
            }
            queryVars[i] = qvar.join("=");
        }
        return queryString[0]+"?"+queryVars.join("&");
    }else{
        return uri+"&"+key+"="+value;
    }
}

function removeQueryStringParameter(uri, key, value) {
    var queryString = uri.split("?");
    var queryVars = queryString[1].split("&");
    for(let i=0;i<queryVars.length;i++){
        if(queryVars[i].includes(key)){
            var temp = queryVars[i].replace(value.replaceAll(" ", "%20")+";","");
            queryVars[i] = temp;
        }
    }
    return queryString[0]+"?"+queryVars.join("&");
}

class FlowerDescriptions extends React.Component{

    getQueryParams(qs){
        let url = window.location.search.substr(1);
        if(url.split("=")[0] === qs){
            return url.split("=")[1]
        }
        return false
    }

    state = {
        show: false,
        flowers: []
    }

    triggerShow = () => {
        this.setState({
            show:!this.state.show
        })
    }

    client = contentful.createClient({
        space: 'ot7r6s5ulsqb',
        accessToken: 'GRjQqpWDqDUO5lrdeen85n2ooSl8oj0wUOa6UjSQzrQ'
    })

    componentWillMount(){
        let query = this.getQueryParams("id")
        this.client.getEntries({
            "sys.id":query
        }).then((response) => {
            this.setState({
                flowers: response.items
            })
        })
    }

    componentWillUpdate(){
        $("#imgpar").height(($(".img").width()*1.4)+100);

        if ($(window).width() < 1000){
            $(".desc").appendTo("#swap");
            if( !$.trim( $('.iconContainer').html() ).length ){
                $(".iconContainer").hide(1);
            }
            $("#imgpar").height(($(".img").width()*1.4));
            $(".ctaButton").css({
                'margin-left' : 0,
                'margin-right' : 0
            });
            $(".ctaButton").click(function(){
                window.location='tel:905-453-8634';
            });
        }

        var urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('flowername') != null){
            var flowernames = urlParams.get('flowername');
            var flowernameaddons = flowernames.split(';');
            var flowerprice = urlParams.get('flowerprice');
            var flowerpriceaddons = flowerprice.split(';');
            for(let i=0;i<flowernameaddons.length-1;i++){
                $("<div onclick='$(this).remove();window.location = removeQueryStringParameter(removeQueryStringParameter(window.location.href, \"flowername\", \""+flowernameaddons[i]+"\"), \"flowerprice\", \""+flowerpriceaddons[i]+"\");' class='addons'> &emsp; <i class=\"fas fa-minus\"></i> "+flowernameaddons[i]+" | $"+flowerpriceaddons[i]+" </div>").appendTo(".descFlo");
            }
        }

        if(urlParams.get('accname') != null){
            var accnames = urlParams.get('accname');
            var accnameaddons = accnames.split(';');
            var accprice = urlParams.get('accprice');
            var accpriceaddons = accprice.split(';');
            for(let i=0;i<accnameaddons.length-1;i++){
                $("<div onclick='$(this).remove();window.location = removeQueryStringParameter(removeQueryStringParameter(window.location.href, \"accname\", \""+accnameaddons[i]+"\"), \"accprice\", \""+accpriceaddons[i]+"\");' class='addons'> &emsp; <i class=\"fas fa-minus\"></i> "+accnameaddons[i]+" | $"+accpriceaddons[i]+" </div>").appendTo(".descAcc");
            }
        }

        if(urlParams.get('funerals') != null){
            $('.descAcc').hide(1);
        }

        $("#floModal").on('shown.bs.modal', function(e){
            $("#floModal .contentcell").height($("#floModal .contentcell").width() *1.5);
        });

        $("#accModal").on('shown.bs.modal', function(e){
            $("#accModal .contentcell").height($("#accModal .contentcell").width() *1.5);
        });
    }

    render(){
        $(".icon").click(function(){
            alert();
            $(".img").css("background-image", $(this).css("background-image"));
        })
        return([
            <Modal className="modalMain" show={this.state.show} onHide={this.triggerShow}>
                <Modal.Header closeButton>
                    <Modal.Title><h4 class="modal-title">Call Us Over The Phone</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="modalNumber" onClick={() => window.location='tel:905-453-8634'}>(905) 453-8634</h2>
                    <p>
                        <center>
                            Please talk to our florists to order or ask questions.
                            Online payment method will be coming soon!
                        </center>
                    </p>
                </Modal.Body>
            </Modal>,

            <div class="container">
                <div class="row" id="swap">
                 	<div class="col-md-1"></div>
                    <div class="col-md-5 desc">
                        <h1> {this.state.flowers[0] && this.state.flowers[0].fields.title}  </h1>
                        <h4> {this.state.flowers[0] && this.state.flowers[0].fields.price} </h4> <br/>
                        <h4> {this.state.flowers[0] && this.state.flowers[0].fields.description} </h4><br/><br/>
                        <h6> Any flower, size or color is customizable to your choice. </h6>
                            <button onClick={this.triggerShow} className="ctaButton">
                                Call Us Now
                            </button><br/><br/><br/>
                        {/*<div class="descAcc">
                            <i class="fas fa-plus"></i>
                            {<a onClick={()=>$('#accModal.modal').removeClass('fade')} data-toggle="modal" data-target="#accModal"> Add Accessories </a><br/>}
                        </div>*/}
                    </div>
                    <div class="col-md-5" id="imgpar">
                        <div class="img" style={{backgroundImage:"url("+ (this.state.flowers[0] && this.state.flowers[0].fields.images[0].fields.file.url) +")"}}></div>
                        <div class="iconContainer">
                            {
                                this.state.flowers[0] &&
                                this.state.flowers[0].fields.images.map((image, i) => {
                                    if(i > 0){
                                        return <div class='icon' style={{backgroundImage:"url("+image.fields.file.url+")"}}></div>
                                    }

                                    return "";
                                })
                            }
                        </div>
                    </div>
                  	<div class="col-md-1"></div>
                </div>
            </div>
        ]);
    }
}

export default FlowerDescriptions;
