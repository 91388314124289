import React from 'react';
import $ from 'jquery';
import '../weddings.css';
const contentful = require('contentful');
const ReactMarkdown = require('react-markdown');

class ImageAndText extends React.Component{

    render(){
        return <div class="container blogs">
            <div class="row">
                {this.props.image && this.props.image.fields.file &&
                    <div class="col-md-6 blogimage" style={{"backgroundImage":"url("+this.props.image.fields.file.url+")"}}>
                    </div>
                }
                {this.props.text &&
                    <div class="col-md-6" style={{padding:"20px"}}>
                        <ReactMarkdown source={this.props.text} escapeHtml={false} />
                    </div>
                }
            </div>
        </div>;
    }
}

export default ImageAndText;
