import React, {useState} from 'react';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const contentful = require('contentful');
const ReactMarkdown = require('react-markdown')

class Banner extends React.Component{

    state = {
        show : false,
        bannerImg: "url("+this.props.bannerImage.fields.file.url+")"
    }

    componentWillMount(){
        if($(document).width() <= 1000){
            if(this.props.mobileBannerImage){
                this.setState({
                    bannerImg: "url("+this.props.mobileBannerImage.fields.file.url+")"
                });
            }
        }
    }

    triggerShow = () => {
        this.setState({
            show:!this.state.show
        })
    }

    render(){


        return [<Modal className="modalMain" show={this.state.show} onHide={this.triggerShow}>
                    <Modal.Header closeButton>
                        <Modal.Title><h4 class="modal-title">Call Us Over The Phone</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h2 className="modalNumber" onClick={() => window.location='tel:905-453-8634'}>(905) 453-8634</h2>
                        <p>
                            <center>
                                Please talk to our florists to order or ask questions.
                                Online payment method will be coming soon!
                            </center>
                        </p>
                    </Modal.Body>
                </Modal>,
                <div className="frontBanner" style={{"backgroundImage":this.state.bannerImg}}>
                    <h2 className="row bannerText"> <ReactMarkdown source={this.props.bannerText} escapeHtml={false}/> </h2>
                    <div className="row bannerButtons">
                        {this.props.bannerButtons.split("\n").map((rows) => {
                            if(rows.split(" - ")[1]==="/"){
                                return <div className="col-lg-2 bannerButton-parent">
                                    <button onClick={this.triggerShow} className="bannerButton">
                                        {rows.split(" - ")[0]}
                                    </button>
                                </div>
                            }else{
                                return <div className="col-lg-2 bannerButton-parent">
                                    <div onClick={() => window.open(rows.split(" - ")[1], "_blank")} className="bannerButton">
                                        {rows.split(" - ")[0]}
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                </div>
            ]
    }
}

export default Banner;
