import React from 'react';
import $ from 'jquery';
import ImageGallery from 'react-image-gallery';
const contentful = require('contentful');
const ReactMarkdown = require('react-markdown');

class Gallery extends React.Component{
    state = {
        images: this.props.images.map((image) => {
            return {
                original:image.fields.file.url,
                thumbnail:image.fields.file.url
            }
        })
    }

    handleClose = () => {
        this.setState({
          isOpen: false
        });
    };

    render(){
        return <>
            {this.props.label &&
                <div class="container blurb">
                    <h4> {this.props.label} </h4>
                    <font><ReactMarkdown source={this.props.text} escapeHtml={false}/> </font>
                </div>
            }
            <div className="container galleryMain">
                <ImageGallery showPlayButton={false} items={this.state.images} />
            </div>
        </>
    }
}

export default Gallery;
