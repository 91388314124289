import React from 'react';
import $ from 'jquery';
const contentful = require('contentful');
const ReactMarkdown = require('react-markdown')

class FlowerLists extends React.Component{
    state = {
        flower:[],
        flowerLists: []
    }

    client = contentful.createClient({
        space: 'ot7r6s5ulsqb',
        accessToken: 'GRjQqpWDqDUO5lrdeen85n2ooSl8oj0wUOa6UjSQzrQ'
    })

    componentDidMount(){
        let order = "";
        if(this.props.defaultOrder==="Asc. Alphabetical"){
            order = "fields.title";
        }else if(this.props.defaultOrder==="Desc. Alphabetical"){
            order = "-fields.title";
        }else if(this.props.defaultOrder==="Asc. Date"){
            order = "sys.createdAt";
        }else if(this.props.defaultOrder==="Desc. Date"){
            order = "-sys.createdAt";
        }else if(this.props.defaultOrder==="Asc. Price"){
            order = "fields.price";
        }else if(this.props.defaultOrder==="Desc. Price"){
            order = "-fields.price";
        }

        this.client.getEntries({
            content_type:'flower',
            order:order,
            limit: 1000
        }).then((response) => {
            this.setState({
                flower: response.items.filter((v) => v.fields.aggregator&&v.fields.aggregator.includes(this.props.aggregationTag)),
                flowerLists: this.setFlowers(response.items.filter((v) => v.fields.aggregator&&v.fields.aggregator.includes(this.props.aggregationTag)))
            })
        })

        $("select").on("change", (e) => {
            let value = $("select option:selected").text()==="Choose a filter"?"": $("select option:selected").text().toLowerCase()
            this.setState({
                flowerLists: this.setFlowers(this.state.flower.filter((val)=>{
                    return val.fields.aggregator.includes(value)
                }))
            })
        })
    }

    setFlowers(items){
        let temp = [];
        return items.map((flowers, i) => {
            return (
                <div class='col-md-3'>
                    <div class='contentcell' style={{backgroundImage: "url("+flowers.fields.images[0].fields.file.url+")"}}  onClick={()=>window.location="/flowers?id="+flowers.sys.id}></div>
                    <div class='contentspan'>{flowers.fields.title}</div>
                    <div class='contentsub'>{flowers.fields.price}</div>
                </div>
            )
        })
    }

    componentDidUpdate(){
        $(".contentcell").height( $(".contentcell").width() * 1.5 );
        if ($(window).width() < 1000){
            $(".contentrow").children().removeAttr("class").addClass("col-xs-6");
            $(".contentrow").children().css({
                "width":"42.5%",
                "margin-left":"5%"
            });
            $(".contentspan").css("font-size","0.95rem");
            $(".contentsub").css("font-size","0.85rem");
            $(".contentcell").height( $(".contentcell").width() * 1.5);
        }
    }

    render(){
        return [
            <div class="container blurb">
                <h4> {this.props.label} </h4>
                <font><ReactMarkdown source={this.props.text} escapeHtml={false}/> </font>
            </div>,
            <div class="container content">
                <div className="row filter-rows">
                    <div className="col-md-3 offset-9 mobfilter">
                        <select class="filters-filters" name="filters">
                            <option value="">Choose a filter</option>
                            {this.props.filterKeys && this.props.filterKeys.map((elem)=>
                                <option value={elem}>{elem}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className={"row contentrow flowerrow"}>
                    {
                        this.state.flowerLists
                    }
                </div>
            </div>
        ]
    }
}

export default FlowerLists;
