import React from 'react';
import $ from 'jquery';
import "./main.css";
import NavigationBar from './sections/NavigationBar.js';
import FlowerLists from './sections/FlowerLists.js';
import FlowerDescriptions from './sections/FlowerDescriptions.js';
import Gallery from './sections/Gallery.js';
import Banner from './sections/Banner.js';
import Categories from './sections/Categories.js';
import ImageAndText from './sections/ImageAndText.js';

class Page extends React.Component{

    // navigationBar //
    navigationBar(bannerImage, shopTitle, address, addressLink, phone, instaTag, instaLink, menus, color){
        return <NavigationBar bannerImage={bannerImage}
                              shopTitle={shopTitle}
                              address={address}
                              addressLink={addressLink}
                              phone={phone}
                              instaTag={instaTag}
                              instaLink={instaLink}
                              menus={menus}
                              color={color}
                />;
    }

    // flowerLists //
    flowerLists(aggregationTag, defaultOrder, search, label, text, filtersBy, filterKeys){
        return <FlowerLists aggregationTag={aggregationTag}
                            defaultOrder={defaultOrder}
                            search={search}
                            label={label}
                            text={text}
                            filtersBy={filtersBy}
                            filterKeys={filterKeys}
                />;
    }

    // flowerDescriptions //
    flowerDescriptions(){
        return <FlowerDescriptions/>;
    }

    // gallery //
    gallery(images, label, text){
        return <Gallery images={images}
                             label={label}
                             text={text}
                />;
    }

    // banner //
    banner(bannerText, bannerButtons, bannerImage, mobileBannerImage){
        return <Banner bannerText={bannerText}
                       bannerButtons={bannerButtons}
                       bannerImage={bannerImage}
                       mobileBannerImage={mobileBannerImage}
                />;
    }

    // categories //
    categories(listOfTitles, listOfLinks, listOfImages){
        return <Categories listOfTitles={listOfTitles}
                       listOfLinks={listOfLinks}
                       listOfImages={listOfImages}
                />;
    }

    // imageAndText //
    imageAndText(image, text){
        return <ImageAndText
                       image={image}
                       text={text}
                />;
    }

    // RENDER //
    render(){
        return this.props.content.map((section) => {
            if(section.sys.contentType.sys.id === "navigationBar"){
                return this.navigationBar(
                       section.fields.logoImage.fields.file.url,
                       section.fields.shopTitle,
                       section.fields.address,
                       section.fields.addressLink,
                       section.fields.phone,
                       section.fields.instaTag,
                       section.fields.instaLink,
                       section.fields.menuItems,
                       section.fields.color
                );
            }

            if(section.sys.contentType.sys.id === "flowerLists"){
                return this.flowerLists(
                       section.fields.aggregationTag,
                       section.fields.defaultOrder,
                       section.fields.hasSearchBar,
                       section.fields.label,
                       section.fields.text,
                       section.fields.filtersBy,
                       (section.fields.filterKeys && section.fields.filterKeys) || ""
                );
            }

            if(section.sys.contentType.sys.id === "flowerDescriptions"){
                return this.flowerDescriptions();
            }

            if(section.sys.contentType.sys.id === "gallery"){
                return this.gallery(
                    section.fields.images,
                    section.fields.label,
                    section.fields.text
                );
            }

            if(section.sys.contentType.sys.id === "banner"){
                return this.banner(
                    section.fields.bannerText,
                    section.fields.bannerButtons,
                    section.fields.bannerImage,
                    section.fields.mobileBannerImage
                );
            }

            if(section.sys.contentType.sys.id === "categories"){
                return this.categories(
                    section.fields.listOfTitles,
                    section.fields.listOfLinks,
                    section.fields.listOfImages
                );
            }

            if(section.sys.contentType.sys.id === "imageAndText"){
                return this.imageAndText(
                    section.fields.image,
                    section.fields.text
                );
            }

            return "";
        })
    }
}

export default Page;
