import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Page from "./Page";
const contentful = require('contentful');

class Main extends React.Component{
    state = {
        data: []
    }

    client = contentful.createClient({
        space: 'ot7r6s5ulsqb',
        accessToken: 'GRjQqpWDqDUO5lrdeen85n2ooSl8oj0wUOa6UjSQzrQ'
    })

    componentDidMount(){
        this.client.getEntries({
            content_type:'page'
        }).then((response) =>
            this.setState({
                data: response.items
            })
        )
    }

    render(){
        return(
            <Router>
                {
                    this.state.data.map((page) =>
                        <Route exact path={page.fields.path} component={() => <Page content={page.fields.content}/>}/>
                    )
                }
            </Router>
        )
    }
}

ReactDOM.render(<Main />, document.getElementById('root'));
serviceWorker.unregister();
