import React from 'react';
import $ from 'jquery';
import MaterialIcon, {colorPalette} from 'material-icons-react';

class NavigationBar extends React.Component{
    state = {
        menu: false
    }

    showMenu(){
        if(!this.state.menu){
            $(".mobileNavBottom").slideDown();
            $(".material-icons.md-dark").html("close");
            this.setState({
                menu: true
            })
        }else{
            $(".mobileNavBottom").slideUp();
            $(".material-icons.md-dark").html("menu");
            this.setState({
                menu: false
            })
        }

    }

    render(){
        return  <div className="nav">
                    {$(document).width()>1000 &&
                        [<div className="row topNavbar">
                            <div className="col-md-1 offset-1 logoCols" onClick={()=> window.location='/'} style={{'backgroundImage':'url('+this.props.bannerImage+')'}}></div>
                            {
                                this.props.menus.split("\n").map((row, i) => {
                                    if(i===0){
                                        return <div className="col-md-1 offset-3 menuCols"><a href={row.split(" - ")[1]}>{row.split(" - ")[0]}</a></div>
                                    }else{
                                        return <div className="col-md-1 menuCols"><a href={row.split(" - ")[1]}>{row.split(" - ")[0]}</a></div>
                                    }
                                })
                            }
                            <div className="col-md-1"></div>
                        </div>,
                        <div className="row bottomNavbar" style={{"backgroundColor":this.props.color}}>
                            <div className="col-lg-3 offset-1"><a target="_blank" href={this.props.instaLink}>{this.props.instaTag}</a></div>
                            <div className="col-lg-4 centerCol">{this.props.phone}</div>
                            <div className="col-lg-3 lastCol"><a target="_blank" href={this.props.addressLink}>{this.props.address}</a></div>
                        </div>]
                    }
                    {$(document).width()<=1000 &&
                        [<div className="row mobileNavTop" style={{"backgroundColor":this.props.color}}>
                            <div className="col-xs-1 mobileIcon" onClick={() => this.showMenu()}>
                                <MaterialIcon icon="menu" color="white"/>
                            </div>
                            <div className="col-xs-4 mobileTitle">
                                <span>{this.props.shopTitle}</span>
                            </div>
                        </div>,
                        <div className="row mobileNavBottom" style={{"border":"4px solid "+this.props.color}}>
                            {
                                this.props.menus.split("\n").map((row, i) => {
                                    return <div className="col-lg-1 menuCols"><a href={row.split(" - ")[1]}>{row.split(" - ")[0]}</a></div>
                                })
                            }
                        </div>]
                    }
                </div>
    }
}

export default NavigationBar;
